/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'core-js/stable'
import 'regenerator-runtime/runtime'

// Rails 系のライブラリの読み込み
import Rails from 'rails-ujs'
import Turbolinks from 'turbolinks'

// その他ライブラリの読み込み
import 'jquery'
import 'bootstrap/dist/js/bootstrap.bundle'

// actiontextのライブラリ読み込み
import 'trix'
import 'actiontext'

// App のメインJS
import App from '../javascripts/application/App'

// スタイルシートのビルド
import "./application-styles.scss";

Rails.start()
Turbolinks.start()

// 画像の読み込み
require.context('../images', true)
// TODO
// /images/returnsにまとめる
// require.context('../images/returns', true)

const app = new App()
app.start()
